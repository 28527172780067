import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

import { Box, CircularProgress, LinearProgress } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import ReactPaginate from "react-paginate";
import Layout from "../../components/layout/Navbar";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";

import BasicSelect from "../../components/customCore/select";
import BasicSelect2 from "../../components/customCore/jobNameDroupDown";
import { categoryTypeForJobSheet } from "../../api/detailsApi";
import { useToasts } from "react-toast-notifications";

const useStyles = makeStyles((theme) => ({
  Avatar: {
    cursor: "pointer",
  },
  Progress: {
    margin: "9px",
    width: "24px !important",
    height: "24px !important",
  },
  update: {
    backgroundColor: "#32c232",
    padding: "6px",
    borderRadius: "12px",
    color: "#fff",
    width: "120px",
  },

  notupdate: {
    backgroundColor: "#e21717",
    padding: "6px",
    borderRadius: "12px",
    color: "#fff",
    width: "120px",
  },

  formMain: {
    display: "flex",
    justifyContent: "space-around",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  select1: {
    minWidth: "300px",
    margin: "12px",
  },
  subDiv: {
    display: "flex",
    maxWidth: "800px",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      width: "300px",
      margin: "0 auto",
    },
  },
  label: {
    paddingLeft: "40px",
    fontSize: "16px",
    color: "#00000A",
    opacity: "0.6",
    fontWeight: 600,
    paddingBottom: "4px",
  },
  filter: {
    width: "140px",
    margin: "12px",

    border: "1px solid #ccc",
    height: "45px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "16px",
    fontFamily: "Poppins",
    borderRadius: "50px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      // marginTop: "100px",
    },
    backgroundColor: "#501abf",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#501abf",
      border: "2px solid #501abf",
      fontWeight: "600",
      "& span": {
        color: "#501abf !important",
      },
    },
    "& span": {
      color: "#fff !important",
    },
  },
  subDiv: {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: "930px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      width: "300px",
      margin: "0 auto",
    },
  },
  select1: {
    minWidth: "300px",
    margin: "12px",
  },
  select2: {
    minWidth: "300px",
    margin: "12px",
    display: "flex",
  },
}));
function RiderDailyReport() {
  const style = useStyles();
  const { addToast } = useToasts();

  const [Category, setCategory] = React.useState([]);
  const [UserSelectCategory, setUserSelectCategory] = React.useState("");
  const [IfVacant, setIfVacant] = React.useState(false);
  const [Option, setOption] = useState("");
  const [Disabled, setDisabled] = React.useState(false);
  const [filterByJobnameAndCategorydata, setfilterByJobnameAndCategoryData] =
    useState([]);
  const [suburbString, setSubrbString] = useState([]);

  // paggnination
  const itemsPerPage = 10;

  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [Loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [LoadingAfterJoin, setLoadingAfterJoin] = useState(false);
  const navigate = useNavigate();
  const [UserSelectCategoryName, setUserSelectCategoryName] =
  React.useState("");
  const [data, setData] = useState([]);

  const [AllAdminData, setAllAdminData] = React.useState([]);
  const AdminId = JSON.parse(localStorage.getItem("auth")).message.id || "";
  useEffect(() => {
    axios
      .get(" https://api.s2.apancollections.com/admindetails/getByAdminId/" + AdminId)
      .then((res) => {
        setAllAdminData(res.data);
        setLoading(true);
      })
      .catch((err) => {
        setLoading(true);
      });
  }, [AdminId]);

  useEffect(() => {
    setDisabled(true);
    if (Option) {
      axios
        .get(` https://api.s2.apancollections.com/jobname/${Option}/${AdminId}`)
        .then((res) => {
          setDisabled(false);
          setCategory(res.data);
          console.log(res.data, 'kkkkk')
        })
        .catch((err) => {
          setDisabled(false);
          setCategory([]);
        });
    }
  }, [Option, AdminId]);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(
      data.slice(itemOffset, endOffset)
    );
    setPageCount(
      Math.ceil(data.length / itemsPerPage)
    );
  }, [itemOffset, itemsPerPage, data]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % data.length;
    setItemOffset(newOffset);
  };
  // useEffect(() => {
  //   const Auth = localStorage.getItem("userInfor");
  //   const message = JSON.parse(Auth);
  //   const AdminId = message.message.id;

  //   axios
  //     .get(`https://api.s2.apancollections.com/jobsBySuburb/${AdminId}/lnds`)
  //     .then((res) => {
  //       setDisabled(false);
  //       setfilterByJobnameAndCategoryData(res.data);
  //       setLoading(true);
  //     })
  //     .catch((err) => {
  //       setCategory([]);
  //       setLoading(true);
  //     });
  // }, []);

  const handleCheckboxChange = (Suburb) => {
    const selectedIndex = selectedRows.indexOf(Suburb);
    let newSelectedRows = [];

    if (selectedIndex === -1) {
      newSelectedRows = newSelectedRows.concat(selectedRows, Suburb);
    } else if (selectedIndex === 0) {
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedRows = newSelectedRows.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }
    setSelectedRows(newSelectedRows);
  };

  const handleExcel = () => {
    const Auth = localStorage.getItem("userInfor");
    const message = JSON.parse(Auth);
    const AdminId = message.message.id;

    const selectedData = data.filter((data) =>
      selectedRows.includes(data.Suburb)
    );
    Category.map((cat) => {
    selectedData.forEach((item) => {
      const dataObj = {
        adminId: AdminId,
        jobType: "lnds",
        surburb: item.Suburb,
        jobId: cat.id
      };
      // console.log(dataObj, 'lllll')
      axios
        .post(`https://api.s2.apancollections.com/jobSheetGenerator`, dataObj)
        .then((res) => {
          setSubrbString(res.data);
          const dataLength = res.data.length;
          var wb = XLSX.utils.book_new(),
            ws = XLSX.utils.json_to_sheet(res.data);

          var today = new Date();
          var todate =
            today.toLocaleDateString() + " " + today.toLocaleTimeString();
          var fileName = item.Suburb + '_' + dataLength + ".xlsx";

          XLSX.utils.book_append_sheet(wb, ws, item.Suburb);
          XLSX.writeFile(wb, fileName);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  })
  };

  const onSelectValue = (value, event) => {
    setOption(value);
  };
  const onSelectValue2 = (value, event) => {
    setUserSelectCategory(value);
  };
  const onSelectName = (value, event) => {
    setUserSelectCategoryName(value);
  };

  const GetData = () => {
    if (Option != "" && UserSelectCategory != "") {
        GetDatas();
    } else {
      addToast("Please Select All Field", {
        appearance: "error",
        autoDismiss: "true",
        autoDismissTimeout: 2000,
      });
    }
  };

  const GetDatas = () => {
    axios
      .get(
        `https://api.s2.apancollections.com/jobsBySuburb/${AdminId}/${Option}/${UserSelectCategory}`
      )
      .then((res) => {
        setData(res.data);
        setDisabled(false);
        setLoadingAfterJoin(false);
      })
      .catch((err) => {
        setDisabled(false);
        setLoadingAfterJoin(false);
        setCategory([]);
        setLoading(true);
      });
  };

  return (
    <Layout>
      <div>
        <div className="Heading">Job Sheet Generator</div>
      </div>
      <br />
      <div className="container15">
        <div className={style.select1}>
          <BasicSelect
            options={categoryTypeForJobSheet}
            value
            onSelectValue={onSelectValue}
            width="250"
          />
        </div>
        <div className={style.select1}>
          <BasicSelect2
            options={Category}
            value
            onSelectValue={onSelectValue2}
            onSelectName={onSelectName}
          />
        </div>
        <button
          type="submit"
          onClick={GetData}
          className={style.filter}
          disabled={Disabled}
        >
          {" "}
          {LoadingAfterJoin ? (
            <CircularProgress
              className={style.Progress}
              style={{ width: "24px", height: "24px" }}
            />
          ) : (
            "Search"
          )}
        </button>
      </div>
      <div
        style={{
          overflowX: "auto",
          marginTop: "2rem",
        }}
        className="table-telecaller"
      >
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            borderSpacing: 0,
          }}
        >
          <tbody>
            <tr>
              <th>
                <span variant="body">No</span>
              </th>
              <th>
                <span variant="body">Count Of Id</span>
              </th>
              <th>
                <span variant="body">State</span>
              </th>
              <th>
                <span variant="body">Suburb</span>
              </th>
              <th>
                <span variant="body">Select</span>
              </th>
            </tr>
          </tbody>
          <tbody>
            {!Loading && Disabled && (
              <tr
                style={{
                  borderCollapse: "collapse",
                  padding: "0px !important",
                }}
              >
                <td
                  style={{
                    textAlign: "center",
                    verticalAlign: "middle",
                    padding: "0px !important",
                  }}
                  colSpan="12"
                >
                  <Box sx={{ width: "100%" }}>
                    <LinearProgress />
                  </Box>
                </td>
              </tr>
            )}
          </tbody>
          <tbody>
            {currentItems &&
              currentItems.length === 0 &&
              !(!Loading && Disabled) && (
                <tr
                  style={{
                    borderCollapse: "collapse",
                    padding: "0px !important",
                  }}
                >
                  <td
                    style={{
                      textAlign: "center",
                      verticalAlign: "middle",
                      padding: "0px !important",
                    }}
                    colSpan="12"
                  >
                    <Box sx={{ width: "100%" }}>No Data</Box>
                  </td>
                </tr>
              )}
          </tbody>
          <tbody>
            {currentItems &&
              currentItems.length > 0 &&
              currentItems.map((data, i) => (
                <tr key={i}>
                  <td
                    style={{
                      textAlign: "center !important",
                      paddingLeft: "10px !important",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        textAlign: "center",
                        paddingLeft: "20px",
                      }}
                      variant="body"
                    >
                      {i + 1}
                    </div>
                  </td>
                  <td
                    style={{
                      textAlign: "center !important",
                      paddingLeft: "10px !important",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        textAlign: "center",
                        paddingLeft: "20px",
                      }}
                      variant="body"
                    >
                      {data.CountOfID}
                    </div>
                  </td>
                  <td>
                    <div
                      style={{
                        width: "100%",
                        textAlign: "center",
                        paddingLeft: "20px",
                      }}
                      variant="body"
                    >
                      {data.State}
                    </div>
                  </td>
                  <td
                    style={{
                      textAlign: "center !important",
                      paddingLeft: "10px !important",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        textAlign: "center",
                        paddingLeft: "20px",
                      }}
                      variant="body"
                    >
                      {data.Suburb}
                    </div>
                  </td>
                  <td
                    style={{
                      textAlign: "center !important",
                      paddingLeft: "10px !important",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        textAlign: "center",
                        paddingLeft: "20px",
                      }}
                      variant="body"
                    >
                      <input
                        type="checkbox"
                        checked={selectedRows.includes(data.Suburb)}
                        onChange={() => handleCheckboxChange(data.Suburb)}
                      />
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <br />
        <ReactPaginate
          breakLabel="..."
          nextLabel="next >"
          onPageChange={handlePageClick}
          pageCount={pageCount}
          previousLabel="< previous"
          renderOnZeroPageCount={null}
          pageRangeDisplayed={3}
          marginPagesDisplayed={3}
          containerClassName={"pagination justify-content-end"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
        />
      </div>
      <div className="job-container">
        <button className="generateButton mt-4" onClick={handleExcel}>
          Generate Job Sheet
        </button>
      </div>
    </Layout>
  );
}
export default RiderDailyReport;
